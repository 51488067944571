<div class="navbar navbar-expand-md navbar-dark bg-white mb-4" role="navigation">
    <!-- <a class="navbar-brand" href="#">Bootstrap 4 NavBar</a> -->
    <div class="logo">
        <a href="/">
            <img *ngIf="about.Company_Logo" src="{{host}}{{about.Company_Logo.url}}" width="150px"
                alt="Owlexa Logo">
        </a>
    </div>
    <button class="navbar-toggler bg-dark" type="button" data-toggle="collapse" data-target="#navbarCollapse"
        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link" href="/">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/owlexa/about-owlexa">Tentang Kami</a>
            </li>

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="dropdown1" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">Layanan</a>
                <ul class="dropdown-menu" aria-labelledby="dropdown1">
                    <li *ngFor="let service of services" class="dropdown-item"><a
                            href="/owlexa/services/{{service.id}}">Layanan {{ service.Title }}</a></li>
                </ul>
            </li>

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="dropdown2" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">Provider</a>
                <ul class="dropdown-menu" aria-labelledby="dropdown1">
                    <li class="dropdown-item"><a href="owlexa/providers">Jaringan Provider Owlexa</a></li>
                    <li class="dropdown-item"><a href="owlexa/new-registration">Pendaftaran Provider Baru</a></li>
                    <li class="dropdown-item"><a href="https://webprovider.owlexa.com">Login Web Provider</a></li>
                </ul>
            </li>

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="dropdown3" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">Berita & Artikel</a>
                <ul class="dropdown-menu" aria-labelledby="dropdown1">
                    <li class="dropdown-item"><a href="owlexa/news">Press Release / Berita</a></li>
                    <li class="dropdown-item"><a href="owlexa/articles">Artikel</a></li>
                </ul>
            </li>

            <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="dropdown2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Info Lainnya</a>
                <ul class="dropdown-menu" aria-labelledby="dropdown2">
                    <li class="dropdown-item"><a href="owlexa/events">Event</a></li>
                    <li class="dropdown-item"><a href="owlexa/faq">FAQ</a></li>
                    <li class="dropdown-item"><a href="owlexa/promotions">Promo</a></li>
                    <li class="dropdown-item"><a href="owlexa/careers">Karier</a></li>
                    <li class="dropdown-item"><a href="owlexa/clients">Klien Berharga</a></li>
                    <li class="dropdown-item"><a href="owlexa/login-member">Login Member</a></li>
                    <li class="dropdown-item dropdown">
                        <a tabindex="-1" href="#" class="dropdown-toggle" id="dropdown2-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Login Perusahaan</a>
                        <ul class="dropdown-menu" aria-labelledby="dropdown2-1">
                            <li class="dropdown-item"><a tabindex="-1" href="http://owlrt.owlexa.com/">Reporting</a></li>
                            <li class="dropdown-item"><a href="https://app.owlexa.com/webmonitoring/login">Monitoring</a></li>
                            <li class="dropdown-item"><a href="https://app.owlexa.com/dashboard/login">Dashboard</a></li>
                        </ul>
                    </li>
                </ul>
            </li> -->

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="dropdown2" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">Info Lainnya</a>

                <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
                    <li class="dropdown-item"><a href="owlexa/events">Event</a></li>
                    <li class="dropdown-item"><a href="owlexa/faq">FAQ</a></li>
                    <li class="dropdown-item"><a href="owlexa/promotions">Promo</a></li>
                    <li class="dropdown-item"><a href="owlexa/careers">Karir</a></li>
                    <li class="dropdown-item"><a href="owlexa/clients">Klien Berharga</a></li>
                    <li class="dropdown-item"><a href="owlexa/login-member">Login Member</a></li>
                    <li class="divider"></li>
                    
                    <li class="dropdown-submenu" style="margin-left: 24px;">
                        <a tabindex="-1" href="#">Login Perusahaan</a>
                        <div class="d-lg-none" style="width: 100px;">
                            <ul class="dropdown-menu show">
                                <li style="margin-left: 20px;"><a tabindex="-1" href="{{ directLogin.Reporting }}">Reporting</a></li>
                                <li style="margin-left: 20px;"><a href="{{ directLogin.Monitoring }}">Monitoring</a></li>
                                <li style="margin-left: 20px;"><a href="{{ directLogin.Dashboard }}">Dashboard</a></li>
                            </ul>
                        </div>
                        <ul class="dropdown-menu">
                            <li style="margin-left: 20px;"><a tabindex="-1" href="{{ directLogin.Reporting }}">Reporting</a></li>
                            <li style="margin-left: 20px;"><a href="{{ directLogin.Monitoring }}">Monitoring</a></li>
                            <li style="margin-left: 20px;"><a href="{{ directLogin.Dashboard }}">Dashboard</a></li>
                        </ul>
                    </li>
                </ul>
            </li>

            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="dropdown3" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">Survey</a>
                <ul class="dropdown-menu" aria-labelledby="dropdown1">
                    <li class="dropdown-item"><a href="owlexa/provider-survey">Provider</a></li>
                    <li class="dropdown-item"><a href="owlexa/corporation-survey">Korporasi</a></li>
                    <li class="dropdown-item"><a href="owlexa/member-survey">Member</a></li>
                </ul>
            </li>

            <li class="nav-item">
                <a class="nav-link" href="/owlexa/contact">Kontak</a>
            </li>

            <li class="nav-item">
                <a class="nav-link" href="https://www.owlexa.ai">Owlexa AI</a>
            </li>

        </ul>
        <form class="form-inline mt-2 mt-md-0">
            <div id="google_translate_element"></div>

            <!-- <input class="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search">
            <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> -->
        </form>
    </div>
</div>
